@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables.css';

@layer base {
  html {
    height: 100%;
    box-sizing: border-box;
    touch-action: manipulation;
  }

  body {
    min-height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-background) !important;
    color: var(--color-text-base);
    .switch-toggle {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}
