@tailwind base;

@layer base {
  :root {
    --color-skunic-blue: #0559af;
    --color-background: #f5f5f5;
    --color-background-blur: #f0eded94;
    --color-wrapper-bg: #ffffff;
    --color-gray-light: #d9d9d9;
    --color-gray-light-2: #f8f8f8;
    --color-gray-light-3: #e9e9e9;
    --color-layer-darkest: #0b0b13;
    --color-layer-darker: #12121a;
    --color-layer-dark: #171722;
    --color-layer-light: #232334;
    --color-layer-lighter: #303044;
    --color-layer-lightest: #454258;
    --color-text-base: #000000;
    --color-text-gray: #b0b0b0;

    --color-green: #3fb68b;
    --color-red: #ff5353;
    --color-yellow: #ffb648;
    --color-teal: #00ddc6;
    --color-bronze: #764727;
    --color-silver: #c1c1c1;
    --color-gold: #ffb846;
    --color-platinum: #7af6c2;
    --color-diamond: #915eff;
    --color-blue: #2e4789;
    --color-blue-light: #3a57b2;
    --color-blue-dark: #1a2d5f;
    --color-max-red: #ce332a;
    --color-max-red-light: #f05851;
    --color-max-red-dark: #a3281f;
    --color-md-sapphire: #4160a8;
    --color-error: #ff0000;
    --color-pending: #f7931a;
    --color-success: #079E95;

    --color-monitor-screen-active-border: #307473;
    --color-monitor-screen-active-bg: #C5F08D ;
    --color-monitor-screen-inactive-border: #212529;
    --color-monitor-screen-inactive-bg: #495057;

    --color-monitor-background-header: #48CAE4;
    --color-monitor-background-content: #d9eff3;

    --color-dark-900: #161522;
  }
}
